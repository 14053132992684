<template>
  <div>
    <v-navigation-drawer
      :key="username"
      :mini-variant="!activeSide"
      app
      clipped
      hide-overlay
      :style="{ top: $vuetify.application.top + 4 + 'px', zIndex: 6 }"
      color="primary"
      dark
      permanent
      :temporary="$vuetify.breakpoint.xs ? true : false"
      enable-resize-watcher
    >
      <v-list dense shaped>
        <v-list-item id="user" two-line :mini-variant="activeSide" class="px-0">
          <v-list-item-avatar>
            <Avatar :name="username" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              :style="{
                color: '#FFF',
                'font-weight': 'bold',
                'font-size': '1em !important',
              }"
            >
              {{ username }}
            </v-list-item-title>
            <v-list-item-subtitle
              :style="{
                color: '#fff',
                'font-size': '0.8rem',
                'font-weight': '350',
              }"
            >
              {{ nameCompany }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              :style="{
                color: '#9e9e9e',
                'font-size': '0.8rem',
                'font-weight': '350',
              }"
            >
              {{ desTypeUser }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-group color="white" v-model="activeTrip">
          <template #activator>
            <v-list-item-icon>
              <v-icon size="18">fa-solid fa-truck-moving</v-icon>
            </v-list-item-icon>
            <v-list-item-title id="titletab">
              {{ $t("components.layout.sidebar.travels") }}
            </v-list-item-title>
          </template>
          <v-list-item to="/trips/offers" class="mt-1">
            <v-list-item-icon>
              <v-icon size="20"> fa-solid fa-dollar-sign </v-icon>
            </v-list-item-icon>
            <v-list-item-title id="titletab">{{ $t("components.layout.sidebar.offered") }} </v-list-item-title>
          </v-list-item>
          <v-list-item to="/trips/noassign" class="mt-1" v-if="typeUser == 1">
            <v-list-item-icon>
              <v-icon size="20"> fa-solid fa-xmark </v-icon>
            </v-list-item-icon>
            <v-list-item-title id="titletab"
              >{{ $t("components.layout.sidebar.not_offer_assigned") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/trips/active" class="mt-1">
            <v-list-item-icon>
              <v-icon size="20"> fa-solid fa-check </v-icon>
            </v-list-item-icon>
            <v-list-item-title id="titletab">{{ $t("components.layout.sidebar.assets") }} </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group color="white" v-if="typeUser == 1">
          <template #activator>
            <v-list-item-icon>
              <v-icon size="20">fa-solid fa-chart-column</v-icon>
            </v-list-item-icon>
            <v-list-item-title id="titletab">
              {{ $t("components.layout.sidebar.reports") }}
            </v-list-item-title>
          </template>
          <v-list-item to="/reports/offers">
            <v-list-item-icon>
              <v-icon size="20">fa-solid fa-sack-dollar</v-icon>
            </v-list-item-icon>
            <v-list-item-title id="titletab" color="white--text">
              {{ $t("components.layout.sidebar.status_of_offers") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/reports/availability">
            <v-list-item-icon>
              <v-icon size="20">fa-solid fa-check-double</v-icon>
            </v-list-item-icon>
            <v-list-item-title id="titletab" color="white--text">
              {{ $t("components.layout.sidebar.availability") }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item @click="onOpenForm('settings')">
          <v-list-item-icon>
            <v-icon size="20">fa-solid fa-gear</v-icon>
          </v-list-item-icon>
          <v-list-item-title id="titletab">
            {{ $t("components.layout.sidebar.settings") }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="onOpenForm('about')">
          <v-list-item-icon>
            <v-icon size="19">fa-solid fa-circle-info</v-icon>
          </v-list-item-icon>
          <v-list-item-title id="titletab">
            {{ $t("components.layout.sidebar.about") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click.stop="logout()">
          <v-list-item-icon>
            <v-icon size="20"> fa-solid fa-power-off </v-icon>
          </v-list-item-icon>
          <v-list-item-title id="titletab">
            {{ $t("components.layout.sidebar.logout") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list>
          <v-list-item id="logo">
            <v-list-item-icon>
              <LogoModule width="60" />
            </v-list-item-icon>
            <v-list-item-title
              >ASIGNACIÓN <br />
              DE SERVICIOS
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="dialogAbout" width="400px" persistent v-if="dialogAbout">
      <About @closeForm="onCloseForm('about')" />
    </v-dialog>
    <v-dialog v-model="dialogSettings" width="70%" persistent scrollable v-if="dialogSettings">
      <Settings @closeForm="onCloseForm('settings')" />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    About: () => import(/* webpackChunkName: "About" */ "@/views/About.vue"),
    Settings: () => import(/* webpackChunkName: "Settings" */ "@/views/Settings.vue"),
  },
  data: () => ({
    activeTrip: true,
    dialogSettings: false,
    dialogAbout: false,
  }),
  props: {
    active: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    unlockUser() {
      return JSON.parse(this.user);
    },
    username() {
      return this.unlockUser.user.username;
    },
    name() {
      let user = JSON.parse(this.user);
      return (user.user.name);
    },
    lastName() {
      let user = JSON.parse(this.user);
      return (user.user.lastName);
    },
    nameCompany() {
      let user = JSON.parse(this.user);
      return user.user.nameCompany;
    },
    desTypeUser() {
      return this.$t(this.unlockUser.user.desTypeUser);
    },
    typeUser() {
      return this.unlockUser.user.typeUser;
    },
    activeSide() {
      return this.active;
    },
  },
  methods: {
    ...mapActions("loader", ["showLoader"]),
    async logout() {
      this.showLoader({
        show: true,
      });
      await this.$store.dispatch("auth/logout");
      setTimeout((window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE), 2000);
    },
    onCloseForm(dialog) {
      dialog === "about"
        ? (this.dialogAbout = false)
        : dialog === "settings"
        ? (this.dialogSettings = false)
        : "";
    },
    onOpenForm(dialog) {
      dialog === "about"
        ? (this.dialogAbout = true)
        : dialog === "settings"
        ? (this.dialogSettings = true)
        : "";
    },
  },
};
</script>

<style scoped>
.v-list--dense .v-list-item.v-list-item--two-line,
.v-list-item--dense.v-list-item--two-line {
  min-height: 40px !important;
  margin-left: 7px;
}

.v-list {
  padding: 0 0.2em 0 0 !important;
}

#user {
  border-bottom: 0.5px solid #f05a24;
  margin-left: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.v-avatar.v-list-item__avatar {
  margin: 0.4rem 0.5rem 0.6rem 0.5rem;
}

.v-list--dense .v-list-item .v-list-item__content,
.v-list-item--dense .v-list-item__content {
  padding: 0;
}

.v-navigation-drawer--mini-variant .v-list-item > .v-avatar:first-child {
  margin-left: 0.4em !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 13px;
}

.v-list-group__items .v-list-item {
  padding-left: 1em !important;
}

.v-list-item,
.v-list-group :deep(.v-list-group__header) {
  padding-top: 0px !important;
  margin-top: 0.2em;
  padding-bottom: 0px !important;
}

.v-list--dense .v-list-item.v-list-item--two-line,
.v-list-item--dense.v-list-item--two-line {
  margin-left: 7px !important;
}

#logo :deep(.v-list-item__icon) {
  min-width: 50px;
  height: 40px;
  margin-right: 0;
}

#logo :deep(.v-list-item__title) {
  text-align: center;
}

#logo {
  padding: 0 !important;
}

.title-module {
  color: #ffffff;
  font-style: italic;
  letter-spacing: 0.5px;
  margin-top: 1.1em;
  font-size: 0.2em;
  margin-left: 0;
}

#logomodulex {
  margin-top: 1.2em;
}

a.v-list-item--active.v-list-item.v-list-item--link.theme--dark {
  border-left: 5px solid #f05a24 !important;
}

#titletab {
  font-size: 0.74em;
}

  /* Pantallas  grandes (1300px o más) */
  @media (min-width: 1300px) {

    #titletab {
      font-size: 0.9em;
    }

  }

  /* Pantallas extra grandes (1600px o más) */
  @media (min-width: 1600px) {

    #titletab {
      font-size: 1em;
    }
  }


</style>
